import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: `"Bebas Neue", cursive`,
    body: `'Quicksand', sans-serif`,
  },
  colors: {
    frame1: "#E3EDF4",
    frame2: "#CFDADA",
    dark: {
      400: "#4A5568",
      500: "#2D3748",
    },
  },
})

export default theme
